<template>
  <div class="auth-root">
    <b-spinner 
      v-if="isLoading" 
      class="d-block mx-auto text-primary my-2" 
    />
    <auth-layout 
      v-else 
      :community="community"
      :auth-api-url="authApiUrl"
    >
      <router-view />
    </auth-layout>
  </div>
</template>

<script>
import { selectLangFromCommunity } from '@/@core/utils/collective'
import { translateTranslationTable } from '@/@core/libs/i18n/utils'
import AuthLayout from './layouts/AuthLayout.vue'

export default {
  name: 'AuthRoot',

  components: {
    AuthLayout
  },

  data() {
    return {
      isLoading: true,
      community: null
    }
  },

  computed: {
    communitySlug() {
      return this.$route.params.communityId
    },
    authApiUrl() {
      return (process.env.VUE_APP_AUTH_API_URL || 'https://auth-api.nectios-staging.com/') + 'api/v1/';
    }
  },

  async created() {
    await this.initCommunity()
  },

  methods: {
    async initCommunity() {
      try {
        // console.log('Initializing auth for community:', this.communitySlug)
        
        const authApiUrl = this.authApiUrl;

        if (this.communitySlug) {
          const response = await fetch(
            `${authApiUrl}login?communitySlug=${this.communitySlug}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          
          if (!response.ok) {
            throw new Error('Failed to fetch community data')
          }

          const data = await response.json()
          this.community = data.data

          // Set language
          selectLangFromCommunity(this.community)

          // Set page title
          document.title = this.translate(this.community.name)

          // Set metadata if exists
          if (this.community.headMeta) {
            const metadata = this.community.headMeta
            document.getElementsByName('author').content = metadata.name_author
            document.getElementsByName('description').content = metadata.name_description
            document.getElementsByName('keywords').content = metadata.name_keywords
            document.getElementsByName('twitter:title').content = metadata.name_twitter_title
            document.getElementsByName('twitter:description').content = metadata.name_twitter_description
            document.getElementById('ogtitle').content = metadata.property_og_title
            document.getElementById('ogdescription').content = metadata.property_og_description
          }
        } else {
          console.log('No community slug found, this is My Nectios');
        }

      } catch (error) {
        console.error('Error fetching community:', error)
        // You might want to redirect to an error page or show an error message
      } finally {
        this.isLoading = false
      }
    },

    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field)
    }
  },

  provide() {
    return {
      authApiUrl: process.env.VUE_APP_AUTH_API_URL || 'http://localhost:3000/api/auth/'
    }
  }
}
</script> 