import { isUserLoggedIn, getNewAuthRoute } from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';
import { getDomain } from '@/store/auth/auth.store';
import Vue from 'vue';
import store from '@/store/index';
import i18n from '@/libs/i18n';

export default async function authGuard(to, _, next) {
  let communityId;
  // if (document.referrer.indexOf('appsumo.com') > -1) {
  //   console.log('from appsumo');
  // }

  to.params.communitySlug ? communityId = to.params.communitySlug : communityId = to.params.communityId;

  const isLoggedIn = isUserLoggedIn();
  if (!isLoggedIn && to.query.jwttoken) {
    if (to.query.lang) {
      i18n.locale = to.query.lang;
      Vue.$cookies.set('i18n_redirected', i18n.locale);
      store.dispatch('setLocale', i18n.locale);
    }
    await store.dispatch('secureAuth', { token: to.query.jwttoken });
    next();
  }
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      Vue.$cookies.set('lastUrl', window.location, '1h', '/', getDomain(), true, 'Strict');
      let newAuthRoute = '/';
      // console.log('window.location.host', window.location.host);
      const subdomain = window.location.host.split('.')[0] !== 'app' ? `${window.location.host.split('.')[0]}.` : null;
      if (process.env.VUE_APP_ENV === "development") {
        // in local development, redirect to nectios login
        newAuthRoute = 'http://' + subdomain.replace('.', '/nectios/login');
      } else {
        newAuthRoute = getNewAuthRoute({
          slug: communityId || '',
          subdomain: subdomain,
        });
      }

      window.location.href = newAuthRoute;
      return;
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' }); // not yet implemented
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({
      name: 'Resumen',
      params: { communityId },
    });
  }

  return next();
}
