<template>
  <section>
    <base-pagination-container
      :total="total || 0"
      :item-name="$t('organizations.title')"
      :loading="isLoading"
      :items="organizations"
      :per-page="perPage"
      :load-first-page="false"
      :search-placeholder="$t(`${isSocieties ? 'societies' : 'organizations'}.search-placeholder`)"
      :searchable="$route.name !== 'Resumen'"
      :no-show-organizations="noShowOrganizations"
      :filter="(selectedCountry && selectedCountry.length > 0 && selectedCustomFieldsForFilter && selectedCustomFieldsForFilter.length > 0) || (selectedCustomFieldsForFilter && selectedCustomFieldsForFilter.length > 0)"
      :is-searching="isSearching"
      @load-page="handleChanger"
    >
      <template 
        v-if="$route.name !== 'Resumen' && !isForBackoffice" 
        #search-actions
      >
        <!-- Add organization -->
        <div
          v-if="!isSocieties"
          id="create-organization"
          v-b-modal.modal-create-organizations
          class="text-primary ml-1 mb-1 d-flex create-item"
        >
          <b-button
            v-if="isStaff || canAdd"
            variant="link"
            class="p-0"
          >
            <feather-icon icon="PlusIcon" variant="primary" size="20" />
          </b-button>
        </div>
        <!-- Add society -->
        <div
          v-else
          id="create-society"
          class="text-primary ml-1 mb-1 d-flex create-item"
          @click="(isAddOrganizationModalVisible = true)"
        >
          <b-button
            v-if="isStaff || canAdd"
            variant="link"
            class="p-0"
          >
            <feather-icon icon="PlusIcon" variant="primary" size="20" />
          </b-button>
        </div>
      </template>

      <b-row class="media m-0">
        <b-col v-if="showFiltersCol" cols="12" lg="2" class="media__filters pl-0">
            
          <!-- FILTERS COLUMN -->
          <b-card class="addon">
              <!--  -->
              <div class="mx-50 d-flex justify-content-between align-items-center">
                  <h3>
                  {{ $t("media.filters.action.filter") }}
                  </h3>
                  <b-icon-filter
                      v-if="classifiersTree.length > 0"
                      variant="primary"
                      class="d-lg-none"
                      @click="toggleFilters"
                  />
              </div>

              <!-- Classifiers -->
              <div
                  v-if="classifiersTree.length > 0"
                  class="flex-column justify-content-center mb-2 px-50 pb-1 pt-1"
                  :class="areFiltersVisible ? 'd-flex' : 'd-none d-lg-flex'"
              >

                  <!-- Filters Selection -->
                  <b-form-group
                      v-for="classifierType in classifiersTree"
                      :key="classifierType.key"
                      :label="translateTranslationTable(currentLocale, classifierType.name)"
                      :label-for="`classifier-filter-${classifierType.key}`"
                      class="w-100"
                  >
                      <v-select
                          v-model="selectedClassifiersForFilter[classifierType.key]"
                          :name="`classifier-filter-${classifierType.key}`"
                          clearable
                          :searchable=true
                          label="name"
                          placeholder="Select..."
                          :reduce="(a) => a"
                          return-object
                          :options="filterOptions(classifierType)"
                          @input="setSelectedFinalFilter"
                      />
                  </b-form-group>

                  <!-- Filter Logic -->
                  <b-form-group
                      :label="$t('filters.logic')"
                      label-for="filterLogicLabel"
                      class="w-100"
                  >
                      <v-select
                        name="filterLogicLabel"
                        v-model="filterLogic"
                        :searchable=false
                        :clearable="false"
                        label="name"
                        :reduce="(a) => a"
                        return-object
                        placeholder="Select..."
                        :options="orAndOptions"
                      />
                  </b-form-group>

                  <!-- Submit to filter -->
                  <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="applyFilter()"
                  >
                      APPLY FILTER
                  </b-button>
              </div>

              <!-- No filters available -->
              <b-row
                  v-else
                  class="horizontal-placeholder d-none d-lg-block h-100 media-placeholder"
              >
                  <b-col cols="12">
                  <img :src="placeholderFilter" />
                  </b-col>
                  <b-col cols="12">
                  <p class="text-primary">
                      {{ $t("available.message", { itemName: $t("media.filters.action.filter") }) }}
                  </p>
                  </b-col>
              </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" :lg="showFiltersCol ? 10 : 12">
          <div v-if="isLoading" class="h-100 w-100 align-items-center justify-content-center d-flex" style="min-height:600px">
            <b-spinner
              class="my-5"
              small
              type="grow"
              variant="primary"
            />
          </div>
          <app-scroll v-else>
            <!-- Societies -->
            <society-table 
              v-if="isSocieties" 
              :organizations="organizations" 
              :busy="isLoading" 
            />
            <!-- Organizations -->
            <organizations-table
              v-else
              :no-show-organizations="noShowOrganizations"
              :filter-old="(selectedCountry && selectedCountry.length > 0 && selectedCustomFieldsForFilter && selectedCustomFieldsForFilter.length > 0) || (selectedCustomFieldsForFilter && selectedCustomFieldsForFilter.length > 0)"
              :filter="isFiltering"
              :is-searching="isSearching"
              :organizations="organizations"
              :busy="isLoading"
              :societies="isSocieties"
              :is-for-backoffice="isForBackoffice"
            />
          </app-scroll>
        </b-col>
      </b-row>

    </base-pagination-container>

    <!-- Create Societies Modal -->
    <organization-edit-modal
      v-if="isSocieties"
      v-model="isAddOrganizationModalVisible"
      :type-key="typeKey"
      @profile-updated="handleCreatedOrganization"
    />

    <!-- Create Organizations Modal -->
    <b-modal
      v-else
      id="modal-create-organizations"
      v-model="isAddOrganizationModalVisible"
      centered
      hide-footer

      size="lg"
    >
      <template #modal-header>
        <language-selector-header :title="$t('organizations.modal-create-title')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
      </template>
      <organization-spaces-create-modal
        item-type="organizations"
        :selected="selectedLanguage"
        :showExtended="false"
        @create-item="handleCreatedOrganization"
        @close-modal="handleSave"
      />
    </b-modal>
  </section>
</template>

<script>
import vSelect from 'vue-select';

import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import BasePaginationContainer from '@core/components/containers/base/BasePaginationContainer.vue';
import AppScroll from '@/@core/components/app-scroll/AppScroll.vue';

import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import { OrganizationListingType, MainType } from '@copernicsw/community-common';

import OrganizationEditModal from '@/views/apps/organization/components/OrganizationEditModal.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import SocietyTable from '@/views/apps/societies/components/SocietyTable.vue';
import LoadingModal from '@core/components/modal/LoadingModal.vue';
import Ripple from 'vue-ripple-directive';
import {
  ENABLED_APPS_GETTERS,
} from '@/store/enabled-apps/enabled-apps-store-constants';
import OrganizationSpacesCreateModal from '@/views/apps/membershipEvents/components/OrganizationSpacesCreateModal.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import OrganizationsTable from './OrganizationsTable.vue';
import FilterPlaceholder from "@/assets/images/placeholders/light/filters.svg";

const SocietyType = {
  NoType: 0,
  LimitedLiabilityCompany: 1,
  NewCompanyLimitedCompany: 2,
  SlUnipersonal: 3,
  LimitedLaborCompany: 4,
  AnonymousSociety: 5,
  UnipersonalAnonymousSociety: 6,
  LaborStockCompany: 7,
  CooperativeSociety: 8,
  PrivateCivilSociety: 9,
};

export default {
  name: 'OrganizationsPaginatedTable',
  components: {
    vSelect,
    BasePaginationContainer,
    AppScroll,
    OrganizationsTable,
    OrganizationEditModal,
    LoadingModal,
    OrganizationSpacesCreateModal,
    SocietyTable,
    LanguageSelectorHeader,
    OrganizationsFilter:  () => import("./OrganizationsFilter.vue" /* webpackChunkName: "OrganizationsFilter" */)
  },
  directives: {
    Ripple,
  },
  mixins: [CardWidgetMixin, ToastNotificationsMixin],
  props: {
    typeKey: {
      type: String,
      default: null,
    },
    loadFirstPage: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 16,
    },
    listingType: {
      type: Number,
      default: OrganizationListingType.forCommunity,
    },
    isSocieties: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    updateSearch: {
      type: Object,
      default: () => {},
    },
    isForBackoffice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      currentPage: 1,
      currentType: '',
      timeoutID: null,
      filteredOrganizations: null,
      filteredTotal: null,

      organizationType: null,
      societyType: null,
      employeeRange: null,
      selectedLanguage: this.currentLocale,

      areFiltersVisible: false,
      isSearching: false,
      isFiltering: false,
      // Filters data
      showFiltersCol: false,
      filterLogic: { name: this.$t("AND"), value: "AND" },
      nextFilter: null,
      nextCountry: null,
      selectedCountry: [],
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectedCustomFieldsForFilter: [],
      customFieldToSend: {},
      searchString: '',
      showFilterBox: false,
      isAddOrganizationModalVisible: false,
      isDownloading: false,
      isModalVisible: false,
      // countriesOptions: [],
      // latamServices: [],
    };
  },
  computed: {
    filter() {
      return (this.selectedCountry && this.selectedCountry.length > 0) || (this.selectedCustomFieldsForFilter && this.selectedCustomFieldsForFilter.length > 0);
    },
    // Filtros computed
    classifiersTree() {
      if (this.$store.getters.classifiersTree.organizations) {
        return this.$store.getters.classifiersTree.organizations.unpaginated;
      }
      return [];
    },
    classifiers() {
      if (this.$store.getters.classifiers.organizations) {
        return this.$store.getters.classifiers.organizations.unpaginated;
      }
      return [];
    },
    locale() {
      return this.$store.state.locale.locale;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
    // latamClassifiers() {
    //   const classifiers = [];
    //   Object.values(this.sortedCommunityClassifiers[0]).map((item) => {
    //     item.map((i) => classifiers.push(i));
    //   });
    //   const classi = classifiers.map(({ name, index, ...rest }) => {
    //     const names = { name: translateTranslationTable(this.currentLocale, name) };
    //     return { ...names, ...rest };
    //   });
    //   return classi.sort((a, b) => {
    //     const fa = a.name.toLowerCase();
    //     const fb = b.name.toLowerCase();

    //     if (fa < fb) {
    //       return -1;
    //     }
    //     if (fa > fb) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    // },
    // isLatamFM() {
    //   return this.$store.getters.currentCollective.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    // },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        // console.log('item', item)
        const translatedTypeName = translateTranslationTable(this.currentLocale, item.typeName);
        // console.log('item.typeName', item.typeName);
        // console.log('translatedTypeName', translatedTypeName);
        if (!newObject[translatedTypeName]) {
          newObject[translatedTypeName] = [item];
        } else {
          newObject[translatedTypeName].push(item);
        }
      }
      // console.log('newObject', newObject);
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({
          key: row,
          name: row,
          type: 1,
        });
      }
      return [newObject, finalKeys];
    },
    finalOptions() {
      // console.log('this.sortedCommunityClassifiers', this.sortedCommunityClassifiers);
      // console.log('this.customFieldsOptions', this.customFieldsOptions);
      return [].concat(this.sortedCommunityClassifiers[1], this.customFieldsOptions);
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    organizations() {
      if (this.$store.getters.communitiesOthers[this.isSocieties ? 'societies' : !this.isForBackoffice ? 'organizations' : 'platform']) {
        return this.$store.getters.communitiesOthers[this.isSocieties ? 'societies' : !this.isForBackoffice ? 'organizations' : 'platform'].unpaginated;
      }
      return [];
    },
    total() {
      if (this.$store.getters.communitiesOthers[this.isSocieties ? 'societies' : !this.isForBackoffice ? 'organizations' : 'platform']) {
        const { total } = this.$store.getters.communitiesOthers[this.isSocieties ? 'societies' : !this.isForBackoffice ? 'organizations' : 'platform'].meta;
        this.$emit('total', total);
        return total;
      }
      return 0;
    },
    // TO DO: implement SocietyType
    societyTypes() {
      return Object.entries(SocietyType).map(([label, value]) => ({
        value,
        label: this.$t(`organizations.filters.society-type.options.${label}`),
      }));
    },
    organizationTypes() {
      return [];
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions('create', this.isSocieties ? 'society' : 'organization', this.loggedMemberRoles, this.collective);
    },
    noShowOrganizations() {
      if (!this.isSocieties) {
        return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].organizations?.customization?.organizationView === 'none';
      }
      return false;
    },
    // filtered() {
    //   return this.$store.getters.organizationsFilter;
    // },
    // isForBackoffice() {
    //   return this.isForBackoffice;
    // }
    placeholderFilter() {
            return FilterPlaceholder;
    },
    orAndOptions() {
        //return ["OR", "AND"];
        return [
            { name: this.$t("AND"), value: "AND" },
            { name: this.$t("OR"), value: "OR" }
        ];
    },
  },
  watch: {
    async typeKey(newValue) {
      this.isLoading = true;
      await this.fetchOrganizationsPage({ page: 1, forceAPICall: true });
      this.isLoading = false;
    },
    selectedCustomFieldsForFilter(value) {
      if (value === null) {
        this.fetchOrganizationsPage({ 
          page: this.currentPage, 
          searchCountry: this.selectedCountry ?? [], 
          force: true 
        });
      }
    },
    updateSearch() {
      this.handleChanger({ 
        page: this.updateSearch.searchPage, 
        search: this.updateSearch.search, 
        searchCountry: [] 
      });
    },
    // filters(value) {
    //   console.log('filters value', value);
    //   this.areFiltersVisible = value || this.showFilter ? true : false;
    // },
    isMobile(value) {
      return value;
    },
  },
  async created() {
    // console.log('OrganizationsPaginatedTable this.isForBackoffice', this.isForBackoffice);
    this.isLoading = true;
    this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
    await this.fetchOrganizationsPage({ page: this.currentPage, force: true });
    // console.log('this.showFilter', this.showFilter);
    // console.log('this.classifiers', this.classifiers);
    if(this.showFilter && this.classifiers.length <= 0){
      // await this.fetchClassifiers();
      await this.fetchClassifiersTree(true);
      if(this.classifiersTree?.length > 0){
        this.showFiltersCol = true;
      }
    }
    // this.countriesOptions = this.latamClassifiers.filter(({ typeName }) => typeName.es === 'País');
    // this.latamServices = this.latamClassifiers.filter(({ typeID }) => typeID === 404);
    // // Remove duplicated
    // this.countriesOptions = this.countriesOptions.reduce((acc, current) => {
    //   const x = acc.findIndex((item) => item.key === current.key);
    //   if (x === -1) {
    //     return acc.concat([current]);
    //   }
    //   acc[x] = current;
    //   return acc;
    // }, []).sort();

    // this.latamServices = this.latamServices.reduce((acc, current) => {
    //   const x = acc.findIndex((item) => item.key === current.key);
    //   if (x === -1) {
    //     return acc.concat([current]);
    //   }
    //   acc[x] = current;
    //   return acc;
    // }, []).sort();

    this.isLoading = false;
  },
  methods: {
    async handleSave() {
      this.isAddOrganizationModalVisible = false;
      await this.fetchOrganizationsPage({ page: this.currentPage, force: true });
    },
    canFilter() {
      if (this.classifiers.length > 0) {
        this.showFilterBox = true;
      } else {
        this.showFilterBox = false;
      }
    },
    closeModal() {
      this.$bvModal.hide('modal-create-organizations');
    },
    filterOptions(classifierType) {
        let translatedClassifiers = [];
        for(let classifier of classifierType.classifiers){
            //console.log('classifier', classifier);
            translatedClassifiers.push({
                // text: this.translateTranslationTable(this.currentLocale, classifier.name),
                // value: classifier.key,
                name: this.translateTranslationTable(this.currentLocale, classifier.name),
                typeKey: classifierType.key,
                key: classifier.key,
                type: 1,
            });
        }
        return translatedClassifiers;
    },
    // Filtros methods
    toggleFilters() {
      // console.log('this.areFiltersVisible', this.areFiltersVisible);
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        this.showFilterBox = false;
        this.customFieldToSend = null;
        this.finalSelectClassifier = null;
        (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
        (this.filtersIndex = 0),
        (this.filters = []),
        (this.parentClassifiers = []),
        (this.selectedClassifiersForFilter = []);
        this.customFieldsInputs = [];
        this.index = 0;
        this.organizationType = null;
        this.organizationType = null;
        this.societyType = null;
        this.employeeRange = null;
        this.handleChanger({ page: 1, search: '' });
      }else{
        this.showFilterBox = true;
      }
    },
    handleChanger({ page, search }) {
      this.isSearching = search.length > 0;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchOrganizationsPage({
          page,
          searchString: search,
          searchCountry: this.selectedCountry ?? [],
          forceAPICall: true,
          classifiers: this.selectedFilteringClassifiers,
          customFields:
            this.customFieldToSend != null &&
            Object.keys(this.customFieldToSend).length > 0
              ? this.customFieldToSend
              : null,
          filterLogic: this.filterLogic?.value
        });
      }, 800);
    },
    applyFilter() {
      this.isFiltering = true;
      this.handleChanger({ page: 1, search: '' });
    },
    // async setSeletedFinalFilter(selected) {
    //   if (this.selectedCustomFieldsForFilter && this.selectedCustomFieldsForFilter.length > 0) {
    //     this.$store.commit('SET_ORGANIZATIONS_FILTER', [this.selectedCountry, this.selectedCustomFieldsForFilter]);
    //     if (Object.values(this.customFieldToSend)?.length > 0) {
    //       this.customFieldToSend[selected.key] = selected.text;
    //     } else if (this.selectedCustomFieldsForFilter != null) {
    //       this.finalSelectClassifier = this.selectedCustomFieldsForFilter.key;
    //     } else {
    //       this.finalSelectClassifier = [];
    //     }
    //     this.handleChanger({ page: 1, search: '', searchCountry: [] });
    //   }
    // },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        itemType: 'backoffice/classifiers',
        storedKey: 'organizations',
        customName: 'classifiers',
        page: 1,
        requestConfig: {
          modelType: this.isSocieties ? 'society' : 'organizations',
          offset: 0,
          count: 1000,
          dontCheckShares: true
        },
      });
      this.canFilter();
      this.isLoading = false;
    },
    async fetchClassifiersTree(force = false) {
      console.log('fetching classifiersTree');
      await this.$store.dispatch("getItems", {
        itemType: "classifiersTree",
        // itemType: "classifiers",
        storedKey: 'organizations',
        forceAPICall: force,
        requestConfig: {
          modelType: this.isSocieties ? 'society' : 'organization',
          morphType: this.isSocieties ? 'society' : 'organization',
          count: 1000,
        },
      });
    },
    // async setSelectedFilterType(selected) {
    //   // console.log('selected', selected)
    //   // console.log('this.currentLocale', this.currentLocale)
    //   this.CustomFieldToSend = null;
    //   this.finalSelectClassifier = null;
    //   this.selectedCustomFieldsForFilter = null;
    //   this.nextFilter = null;
    //   if (selected?.length === 0) {
    //     this.finalSelectClassifier = null;
    //     this.handleChanger({ page: 1, search: '', searchCountry: [] });
    //   } else {
    //     let filterTranslated = [];
    //     if (selected?.type === 1) {
    //       const selectedClassifiers = this.sortedCommunityClassifiers[0][selected.key];
    //       // console.log('selectedClassifiers', selectedClassifiers);
    //       for (const row of selectedClassifiers) {
    //         // console.log('row.name', row.name)
    //         const translatedClassifierName = translateTranslationTable(this.currentLocale, row.name);
    //         // console.log('translatedClassifierName', translatedClassifierName)
    //         filterTranslated.push({
    //           name: translatedClassifierName,
    //           key: row.key,
    //           type: 1,
    //         });
    //       }
    //     } else if (selected?.type === 2) {
    //       // console.log('filter type 2 with customFieldToSend:', this.customFieldToSend)
    //       this.customFieldToSend[selected.key] = '';
    //       filterTranslated = {
    //         type: 2,
    //         key: selected.key,
    //       };
    //     }
    //     // console.log('filterTranslated', filterTranslated)
    //     this.nextFilter = filterTranslated;
    //   }
    // },
    async setSelectedFinalFilter(selected) {
      // console.log('setSelectedFinalFilter selected', selected);
      // console.log('this.selectedClassifiersForFilter', this.selectedClassifiersForFilter);
      this.selectedFilteringClassifiers = [];
      for(let selectedClassifier of Object.values(this.selectedClassifiersForFilter)){
        // console.log('selectedClassifier', selectedClassifier);
        if(selectedClassifier?.key){
          this.selectedFilteringClassifiers.push(selectedClassifier.key);
        }
      }
      // console.log('this.selectedFilteringClassifiers', this.selectedFilteringClassifiers);
      //Lo vamos a hacer con un botón de buscar, así ahorramos queries innecesarias
      //this.handleChanger();
    },
    async fetchOrganizationsPage({
      page, searchString = '', searchCountry = [], forceAPICall = true, classifiers = [], customFields = [], filterLogic = 'OR'
    }) {
      this.isLoading = true;
      this.currentType = this.typeKey ? this.typeKey : this.communitySlug;
      if (this.$store.getters.organizations[this.currentType]?.unpaginated.length === 1) {
        forceAPICall = true;
      }

      let requestConfigOrganizations = {
          isOrganization: true,
          orderByDate: -1,
          checkStatus: true,
          communityParentSlug: this.$store.getters.currentCollective.slug,
          ...(this.currentType.key > 0 ? { listingType: this.listingType } : ''),
          // ...(this.isForBackoffice ? { mainTypeIDs: 9 } : ''),
          mainTypeIDs: 9,
          avoidShared: true,
          avoidRedis: true,
          getMinimalData: true,
          typeKey: this.currentType !== this.communitySlug ? this.currentType : undefined,
          // avoidRedis: true,
          //SEARCHING
          searchString: searchString && searchString !== '' ? searchString : null,
          //FILTERING
          classifiersOLD: this.selectedCustomFieldsForFilter?.length > 0 ? this.selectedCustomFieldsForFilter : null,
          classifiers: this.selectedFilteringClassifiers,
          customFields:
            this.customFieldToSend != null &&
            Object.keys(this.customFieldToSend).length > 0
              ? this.customFieldToSend
              : null,
          filterLogic: this.filterLogic?.value
      };
      let requestConfigSocieties = {
        listingType: this.listingType,
        orderByName: 1,
        ...(this.selectedFilteringClassifiers?.length > 0 ? { classifiers: [...this.selectedCustomFieldsForFilter, ...this.selectedCountry, ...this.selectedFilteringClassifiers] } : ''),
        typeKey: this.currentType !== this.communitySlug ? this.currentType : undefined,
      };

      // const isFiltering = searchString !== '' || searchCountry?.length >= 0 || classifiers?.length >= 0 || customFields?.length;
      
      // if (isFiltering) {
      //   requestConfigOrganizations.searchString = searchString;
      //   if(this.selectedCustomFieldsForFilter?.length > 0){
      //     requestConfigOrganizations.classifiers = [...this.selectedCustomFieldsForFilter, ...this.selectedCountry];
      //   }

      //   requestConfigSocieties.searchString = searchString;
      // }

      // call backend
      await this.$store.dispatch('getItems', {
        page,
        itemType: this.isSocieties ? 'organizations' : (!this.isForBackoffice ? 'communities/simply' : 'communities/wholeBranch'),
        // itemType: this.isSocieties ? 'organizations' : 'communities/wholeBranch',
        customName: 'communitiesOthers',
        storedKey: this.currentType.key > 0 ? this.currentType.name : this.isSocieties ? 'societies' : !this.isForBackoffice ? 'organizations' : 'platform',
        forceAPICall,
        perPage: this.perPage,
        requestConfig: this.isSocieties ? requestConfigSocieties : requestConfigOrganizations,
      });

      this.isLoading = false;
    },
    async handleCreatedOrganization() {
      this.isLoading = true;
      setTimeout(async () => {
        await this.fetchOrganizationsPage({ page: this.currentPage, force: true });

      }, 2400)
      this.isLoading = false;
    },
    translateTranslationTable,
  },
};
</script>
<style lang="scss" scoped>
.filters-container {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
