<template>
  <div class="navbar-container main-menu-content d-flex">
    <div v-if="title && !isSmall" class="mr-2">
      <p class="font-weight-extrabold mb-0 text-primary h4 truncated-text">
        {{ title }}
      </p>
      <p class="font-medium-1 text-muted mb-0 truncated-text">
        <b-icon icon="grid" font-scale="0.9" class="mr-25" />
        {{ $t('settings.menu.title') }}
      </p>
    </div>

    <ul 
      v-if="showNexosLinks"
    >
    <li
      v-for="item in bancolombiaItemsArray"
      :key="item.title"
      class="nav-item"
      :class="{
        'sidebar-group-active active': isActiveItem(item) || isChild(item),
      }"
    >
        <b-link
          class="nav-link"
          :class="{
            'bg-light rounded':
              item.title === $t('backoffice.back') || item.title === 'Back' || item.title === 'Home',
          }"
          :to="typeof item.route === 'string' ? { name: item.route } : item.route"
        >
          <feather-icon
            v-if="item.title === $t('backoffice.back') || item.title === 'Back'"
            icon="ArrowLeftIcon"
            size="14"
            class="mt-lg-25"
          />
          <span>{{ translate(title(item)) }}</span>
        </b-link>
      </li>
    </ul>

    <!-- Menu tabs from installed Apps published in side menu -->
    <horizontal-nav-menu-items 
      :is-backoffice="isBackoffice" 
      :items="appsInMenu" 
    />
  </div>
</template>

<script>
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';

export default {
  components: {
    HorizontalNavMenuItems,
  },
  props: {
    appsInMenu: {
      type: Array,
      required: true,
    },
    isBackoffice: Boolean,
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isSmall: false,
    };
  },
  computed: {
    apps() {
      return this.$store.getters.apps;
    },
    currentSpaceKey() {
      return this.$store.getters.currentCollective.key;
    },
    mainSpaceKey() {
      return this.$store.getters.mainCollective.key;
    },
    isNexosBancolombia() {
      const isNexos = process.env.VUE_APP_CUSTOMER === 'nexos';
      if(isNexos){
        return true;
      }
      console.log('this.currentSpaceKey', this.currentSpaceKey);
      console.log('this.mainSpaceKey', this.mainSpaceKey);
      const nexosBancolombiaKey = 'f3166db0-b635-11ef-805d-f79ce25412de';
      return this.currentSpaceKey === nexosBancolombiaKey || this.mainSpaceKey === nexosBancolombiaKey;
    },
    isBackoffice() {
      return this.$route.path.includes('/backoffice');
    },
    isMyAccount() {
      return this.$route.path.includes('/myself');
    },
    showNexosLinks() {
      return false;
      
      // console.log(' this.$route.path',  this.$route.path);
      // console.log('this.isNexosBancolombia', this.isNexosBancolombia);
      // console.log('this.isBackoffice', this.isBackoffice);
      // console.log('this.isMyAccount', this.isMyAccount);
      
      // return this.isNexosBancolombia && !this.isBackoffice && !this.isMyAccount;
    },
    bancolombiaItemsArray() {
      return [
        {
          title: "Construyamos juntos",
          route: {
            name: 'Resumen',
            params: {
              communityId: "construyamos-juntos",
            }
          }
        },
        {
          title: "Conócenos",
          route: {
            name: 'Resumen',
            params: {
              communityId: "conocenos",
            }
          }
        },
        {
          title: "Hablemos",
          route: {
            name: 'Resumen',
            params: {
              communityId: "hablemos",
            }
          }
        },
        {
          title: "¿Tienes dudas?",
          route: {
            name: 'Resumen',
            params: {
              communityId: "tienes-dudas",
            }
          }
        },
      ];
    },
  },
  setup() {
    return {
      // navMenuItems,
    };
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    //TODO: delete
    // await this.$store.dispatch('getItems', {
    //   itemType: 'rooms',
    //   // Workaround to ensure that the main room is located
    //   // TODO: request second page if mainRoom is not found
    //   perPage: 1000,
    //   page: 1,
    //   communitySlug: this.$route.params.communityId,
    //   requestConfig: {
    //     orderByDate: -1,
    //   },
    // });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isSmall = window.innerWidth < 1320;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
