<template>
  <backoffice-container>
    <b-card
      no-body
      :class="isMobile ? 'p-2' : 'p-3'"
      :style="
        isMobile
          ? 'min-height: calc(100vh - 100px)'
          : 'min-height: calc(100vh - 125px)'
      "
    >
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t("backoffice.sepa.title") }}</h4>
        <b-button variant="primary" class="ml-1" @click="generateSepaXML">
          {{ $t("backoffice.sepa.generate-xml") }}
        </b-button>
      </div>

      <!-- Loading spinner -->
      <div v-if="isLoading" class="h-100 d-flex align-items-center">
        <b-spinner class="d-block mx-auto mt-3" variant="primary" />
      </div>

      <!-- SEPA files table -->
      <div v-else>
        <div v-if="sepaFiles && sepaFiles.length > 0">
          <b-table
            :items="sepaFiles"
            :fields="tableFields"
            responsive
            hover
            show-empty
          >
            <!-- Date column -->
            <template #cell(generatedAt)="data">
              {{ formatDate(data.item.generatedAt) }}
            </template>

            <!-- Actions column -->
            <template #cell(actions)="data">
              <b-button
                variant="outline-primary"
                size="sm"
                @click="downloadSepaFile(data.item)"
              >
                <feather-icon icon="DownloadIcon" size="16" />
                {{ $t("backoffice.sepa.download") }}
              </b-button>
            </template>
          </b-table>
        </div>

        <!-- Empty state -->
        <div
          v-else
          class="d-flex placeholder align-item-center justify-content-center flex-column my-2"
          style="min-height: 45vh"
        >
          <b-img :src="paymentsPlaceholder" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{ $t("backoffice.sepa.no-files") }}
            </p>
          </div>
        </div>
      </div>

      <!-- Loading modal -->
      <loading-modal
        v-model="isGenerating"
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      />
    </b-card>
  </backoffice-container>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue"
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin"
import PaymentsPlaceholder from "@/assets/images/placeholders/light/payments.svg"
import LoadingModal from "@core/components/modal/LoadingModal.vue"
import { format } from 'date-fns'

export default {
  name: "BackofficeSepa",
  components: {
    BackofficeContainer,
    LoadingModal,
  },
  directives: {
    Ripple,
  },
  mixins: [ToastNotificationsMixin],

  data() {
    return {
      isLoading: false,
      isGenerating: false,
      isMobile: false,
      sepaFiles: [],
      tableFields: [
        {
          key: 'generatedAt',
          label: this.$t('backoffice.sepa.fields.date'),
          sortable: true
        },
        {
          key: 'fileName',
          label: this.$t('backoffice.sepa.fields.filename'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('backoffice.sepa.fields.actions'),
          thClass: 'text-center',
          tdClass: 'text-center'
        }
      ]
    }
  },

  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective?.slug 
        ? this.$store.getters.currentCollective 
        : null
    },
    paymentsPlaceholder() {
      return PaymentsPlaceholder
    }
  },

  created() {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
    this.fetchSepaFiles()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  },

  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth < 700
    },

    async fetchSepaFiles() {
      this.isLoading = true
      try {
        const response = await this.$store.$service.BackendClient.get(
          '/backoffice/sepa/files',
          {
            params: {
              communityKey: this.currentCollective.key
            }
          }
        )
        this.sepaFiles = response.data.data
      } catch (error) {
        this.notifyError(this.$t('backoffice.sepa.errors.fetch'))
      }
      this.isLoading = false
    },

    async generateSepaXML() {
      this.isGenerating = true
      try {
        await this.$store.$service.BackendClient.post(
          '/backoffice/sepa/generate',
          {
            communityKey: this.currentCollective.key
          }
        )
        this.notifySuccess(this.$t('backoffice.sepa.success.generated'))
        await this.fetchSepaFiles()
      } catch (error) {
        this.notifyError(this.$t('backoffice.sepa.errors.generate'))
      }
      this.isGenerating = false
    },

    async downloadSepaFile(file) {
      try {
        const response = await this.$store.$service.BackendClient.get(
          `/backoffice/sepa/download/${file.id}`,
          {
            responseType: 'blob'
          }
        )
        
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        this.notifyError(this.$t('backoffice.sepa.errors.download'))
      }
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
.placeholder-img {
  width: 180px;
  display: block;
}
</style>
