<template>
    <div class="login-wrapper">
      <div>
        <h1 class="card-title fw-bold mb-1 auth-main-title">
          {{ $t('auth.login.title') }}
        </h1>
        <p class="card-text mb-2">
          {{ $t('auth.login.subtitle') }}
        </p>
      </div>

      <b-alert
        v-if="error"
        variant="danger"
        show
        class="text-center w-100 rounded pb-2 pt-2"
      >
        {{ $t(error) }}
      </b-alert>

      <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit">
        <!-- Email -->
        <div class="mb-1">
          <label class="form-label">
            {{ $t('auth.login.form.email.label') }}
          </label>
          <b-form-input
            id="login-email"
            v-model="formData.email"
            type="email"
            name="email"
            :placeholder="$t('auth.login.form.email.placeholder')"
            required
          />
        </div>

        <!-- Password -->
        <div class="mb-1">
          <label class="form-label">
            {{ $t('auth.login.form.password.label') }}
          </label>
          <div class="input-group input-group-merge">
            <b-form-input
              id="login-password"
              v-model="formData.password"
              :type="passwordFieldType"
              name="password"
              :placeholder="$t('auth.login.form.password.placeholder')"
              class="form-control-merge"
              required
            />
            <div class="input-group-append">
              <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
                <feather-icon
                  :icon="passwordToggleIcon"
                  size="16"
                  class="toggle-password-icon"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Forgot Password Link -->
        <div class="mb-1">
          <span>{{ $t('auth.login.forgot-password.message') }}</span>
          <b-link
            class="auth-link"
            :to="{ name: 'auth-forgot' }"
          >
            {{ $t('auth.login.forgot-password.action') }}
          </b-link>
        </div>

        <!-- Submit Button -->
        <div class="auth-btn-container d-flex justify-content-center">
          <b-button
            type="submit"
            variant="primary"
            class="auth-btn auth-btn-primary mt-2 mb-2"
            :disabled="invalid"
          >
            {{ $t('auth.login.form.action.sign-in') }}
          </b-button>
        </div>
      </b-form>

      <!-- Sign Up Link -->
      <p class="text-center mt-2">
        <span>{{ $t('auth.login.sign-up.message') }}</span>
        <b-link :to="{ name: 'auth-signup' }">
          {{ $t('auth.login.sign-up.action') }}
        </b-link>
      </p>
    </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Vue from 'vue'

export default {
  name: 'Login',

  inject: ['authApiUrl'],

  mixins: [togglePasswordVisibility],

  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      error: null,
      invalid: false
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos';
    }
  },

  watch: {
    'formData.email'(newVal) {
      this.validateForm()
    },
    'formData.password'(newVal) {
      this.validateForm()
    }
  },

  async created() {
    // If the communitySlug is "error", redirect to 
    if(this.communitySlug === "error"){
      // console.log('Login - Error is not a valid community slug, redirecting to main root... ');
      if(this.isNexos) {
        const nexosSpaceUrl = process.env.VUE_APP_URL + '/' + process.env.VUE_APP_MAIN_SPACE_SLUG + '/auth/login';
        // console.log('Redirecting to Nexos Login:', nexosSpaceUrl);
        window.location.href = nexosSpaceUrl;
      } else {
        this.$router.push('/login');
      }
    }
  },

  methods: {
    validateForm() {
      // Basic validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.invalid = !this.formData.email || 
                     !this.formData.password || 
                     !emailRegex.test(this.formData.email) ||
                     this.formData.password.length < 6
    },

    async handleSubmit() {
      const endpoint = this.authApiUrl + 'login';

      try {
        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              ...this.formData,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        const token = await response.json()

        const date = new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        )

        // Set cookies for both domains
        Vue.$cookies.set('accessToken', token, {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN_1 || 'localhost',
          expires: date
        })

        Vue.$cookies.set('accessToken', token, {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN_2 || 'localhost',
          expires: date
        })

        // Redirect to app
        const vueAppUrl = process.env.VUE_APP_APP_URL_1 || 'http://localhost:8080';
        const destinationURL = vueAppUrl + '/' + this.communitySlug;
        console.log('destinationURL', destinationURL);

        window.location.href = destinationURL

      } catch (error) {
        this.error = 'login-error'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-merge {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  .form-control {
    &:not(:last-child) {
      border-right: 0;
      padding-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-left: 0;
      padding-left: 0;
    }
  }

  .input-group-append {
    display: flex;
    
    .input-group-text {
      background-color: #f8f8f8;
      border: 1px solid #d8d6de;
      border-left: 0;
      padding: 0.571rem 1rem;
      display: flex;
      align-items: center;
      border-top-right-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
      margin-left: -1px; /* This ensures proper border overlap */
      
      &:hover {
        background-color: #efefef;
      }
    }
  }
}

.toggle-password-icon {
  color: #6e6b7b;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
